import Vue from "vue";
import Router from "vue-router";
import Landing from "./views/Landing.vue";
import Q2 from "./views/Q2.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "landing",
      component: Landing,
    },
    {
      path: "/q2",
      name: "q2",
      component: Q2,
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
