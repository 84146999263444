import APP_API_URL, { APP_DEBUG, APP_ENV } from "./config";
import Store from "../store";
import axios from "axios";

export const ajax = async (obj, vue) => {
  const method = obj.method ? obj.method : "POST";
  const token = Store.state.access_token ? Store.state.access_token : null;
  const headerHttp = token
    ? {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    : { Accept: "application/json", "Content-Type": "application/json" };

  const body = JSON.stringify(obj.data);

  if (APP_DEBUG) {
    console.log(APP_API_URL[APP_ENV] + obj.url, body);
  }
  const res = await axios({
    method: method,
    url: APP_API_URL[APP_ENV] + obj.url,
    data: body,
    params: method == "GET" ? obj.data : null,
    headers: { ...headerHttp, ...obj.headers },
    timeout: 30000,
  }).catch((error) => {
    obj.complete ? obj.complete() : null;
    obj.fail ? obj.fail(error) : null;
    if (error.response.status == 401) {
      window.location.href = "/";
      Store.dispatch("resetState");
    }
    if (obj.isShowError) {
      vue.$notify.error({
        message: error.message,
        position: "top right",
        timeOut: 3000,
      });
    }

    return false;
  });
  if (!res) {
    return false;
  }
  try {
    const responseJson = { ...res.data };
    if (APP_DEBUG) {
      console.log(APP_API_URL[APP_ENV] + obj.url, responseJson);
    }
    obj.complete ? obj.complete(responseJson) : null;
    if (responseJson.isSuccess) {
      obj.success ? obj.success(responseJson) : null;
    } else {
      obj.fail ? obj.fail(responseJson) : null;
      if (obj.isShowError) {
        vue.$notify.error({
          message: responseJson.message,
          position: "top right",
          timeOut: 3000,
        });
      }
    }
  } catch (error) {
    if (APP_DEBUG) {
      console.log(APP_API_URL[APP_ENV] + obj.url, res);
      console.log(error);
    }
    obj.complete ? obj.complete() : null;
    obj.fail ? obj.fail(error) : null;
    vue.$notify.error({
      message: vue.$t(error.message),
      position: "top right",
      timeOut: 3000,
    });
  }
};

export const fileAjax = async (obj, vue) => {
  const method = obj.method ? obj.method : "POST";
  const token = Store.state.access_token ? Store.state.access_token.plainTextToken : null;
  const headerHttp = token
    ? {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      }
    : { Accept: "application/json", "Content-Type": "multipart/form-data" };

  var data = new FormData();
  data.append("image", obj.data[0]);
  obj.data.forEach((element) => {
    data.append("images[]", element);
  });

  if (APP_DEBUG) {
    console.log(APP_API_URL[APP_ENV] + obj.url, data);
  }
  const res = await axios({
    method: method,
    url: APP_API_URL[APP_ENV] + obj.url,
    data: data,
    headers: headerHttp,
    timeout: 30000,
  }).catch((error) => {
    obj.complete ? obj.complete() : null;
    obj.fail ? obj.fail(error) : null;
    if (error.response.status == 401) {
      window.location.href = "/";
      Store.dispatch("resetState");
    }
    vue.$notify.error({
      message: error.message,
      position: "top right",
      timeOut: 3000,
    });
    return false;
  });
  if (!res) {
    return false;
  }
  try {
    const responseJson = { ...res.data };
    if (APP_DEBUG) {
      console.log(APP_API_URL[APP_ENV] + obj.url, responseJson);
    }
    obj.complete ? obj.complete(responseJson) : null;
    if (responseJson.isSuccess) {
      obj.success ? obj.success(responseJson) : null;
    } else {
      obj.fail ? obj.fail(responseJson) : null;
      if (obj.isShowError) {
        vue.$notify.error({
          message: responseJson.message,
          position: "top right",
          timeOut: 3000,
        });
      }
    }
  } catch (error) {
    if (APP_DEBUG) {
      console.log(APP_API_URL[APP_ENV] + obj.url, res);
    }
    obj.complete ? obj.complete() : null;
    obj.fail ? obj.fail(error) : null;
    vue.$notify.error({
      message: vue.$t(error.message),
      position: "top right",
      timeOut: 3000,
    });
  }
};
