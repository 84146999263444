<template>
  <mdb-container>
    <mdb-row v-if="!is_started">
      <mdb-col md="6" offset-md="3">
        <mdb-input label="Number of Players" v-model="no_of_players" />
      </mdb-col>
      <mdb-col md="6" offset-md="3">
        <mdb-btn v-if="!loading" @click="startGame" color="primary">Start Game</mdb-btn>
        <mdb-spinner v-if="loading" />
      </mdb-col>
    </mdb-row>
    <mdb-row v-if="is_started" class="mb-4">
      <mdb-col col="12" class="d-flex flex-row justify-content-between">
        <mdb-btn size="sm" @click="previousIndex" color="primary">Previous Player</mdb-btn>
        <div>Player {{ index + 1 }}</div>
        <mdb-btn size="sm" @click="nextIndex" color="primary">Next Player</mdb-btn>
      </mdb-col>
    </mdb-row>
    <mdb-row v-if="is_started">
      <mdb-col v-for="card in data[index]" v-bind:key="card" md="2">
        <mdb-card class="mb-3" :class="{ h: card.split('-')[0] == 'H', s: card.split('-')[0] == 'S', d: card.split('-')[0] == 'D', c: card.split('-')[0] == 'C' }">
          <mdb-card-body> {{ card }} </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
    <mdb-row v-if="is_started">
      <mdb-col col="12">
        <mdb-btn @click="is_view_response = !is_view_response" size="sm" color="info">View Response</mdb-btn>
      </mdb-col>
      <mdb-col col="12" v-if="is_view_response">
        <samp>
          {{ raw_data }}
        </samp>
        <hr />
        <samp> backend used: Laravel PHP API frondend used: vue.js with mdbvue </samp>
      </mdb-col>
      <mdb-col col="12">
        <mdb-btn @click="restartGame" size="sm" color="danger">Restart</mdb-btn>
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbInput, mdbContainer, mdbRow, mdbCol, mdbBtn, mdbSpinner, mdbCard, mdbCardBody } from "mdbvue";
import { ajax } from "../Helpers/ajax";
export default {
  name: "landing",
  components: {
    mdbInput,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbSpinner,
    mdbBtn,
    mdbCard,
    mdbCardBody,
  },
  data: () => ({
    no_of_players: "",
    loading: false,
    data: {},
    index: 0,
    is_started: false,
    raw_data: [],
    is_view_response: false,
  }),
  methods: {
    startGame() {
      if (!this.no_of_players) {
        this.$notify.error({ message: "Please enter number of players", position: "top right", timeOut: 5000 });
        return;
      }
      if (!/^\d+$/.test(this.no_of_players)) {
        this.$notify.error({ message: "Please enter valid number of players", position: "top right", timeOut: 5000 });
        return;
      }
      this.loading = true;
      ajax(
        {
          url: "public/card",
          method: "GET",
          data: { number_of_people: this.no_of_players },
          complete: () => (this.loading = false),
          success: (result) => {
            let players = {};
            this.raw_data = result.data;
            result.data.forEach((element, index) => {
              players[index] = element.split(", ");
            });
            this.data = Object.assign({}, players);
            this.is_started = true;
          },
          fail: (fail) => this.$notify.error({ message: fail.message, position: "top right", timeOut: 5000 }),
        },
        this
      );
    },
    previousIndex() {
      if (this.index == 0) {
        this.index = this.raw_data.length - 1;
      } else {
        this.index = this.index - 1;
      }
    },
    nextIndex() {
      if (this.index == this.raw_data.length - 1) {
        this.index = 0;
      } else {
        this.index = this.index + 1;
      }
    },
    restartGame() {
      this.data = {};
      this.raw_data = [];
      this.is_started = false;
    },
  },
};
</script>

<style scoped>
.h {
  background-image: url("~@/assets/heart.png");
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 2px;
  background-size: 25px 25px;
}
.s {
  background-image: url("~@/assets/spade.png");
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 2px;
  background-size: 25px 25px;
}
.d {
  background-image: url("~@/assets/diamond.png");
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 2px;
  background-size: 25px 25px;
}
.c {
  background-image: url("~@/assets/club.png");
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 2px;
  background-size: 25px 25px;
}
</style>
