export const APP_DEBUG = process.env.NODE_ENV != "production" ? true : false;
export const APP_ENV = process.env.NODE_ENV;
// export const APP_API_URL = "http://127.0.0.1:8000/api/";
// export const APP_STORAGE_URL = "http://127.0.0.1:8000/storage/";
export default {
  development: "https://xiboapi.hano.my/api/",
  production: "https://xiboapi.hano.my/api/",
};
export const APP_STORAGE_URL = {
  development: "http://127.0.0.1:8000/storage/",
  production: "https://xiboapi.hano.my/storage/",
};
export const APP_PATH = {
  development: "http://127.0.0.1:8000/",
  production: "https://xiboapi.hano.my/",
};
// export const APP_STORAGE_URL = "https://xiboapi.hano.my/storage/";
export const APP_VERSION_NAME = "1.0.0";
