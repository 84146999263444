<template>
  <mdb-container>
    <mdb-row>
      <mdb-col>
        <mdb-btn @click="downloadSQL">Download mySQL</mdb-btn>
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <ol>
          <li>Indexing: use indexing for the fields that frequenctly used for joins and where conditions.</li>
          <li>Reduce unecessary left join</li>
          <li>Archieve legacy data to speed up the progress</li>
          <li>Caching</li>
          <li>Denormalization tables</li>
          <li>Avoid using too much joints</li>
        </ol>
        <samp>
          <p>
            i would suggest for heavy query can look for alternative tools like elastic search and noSQL databases. RDS database will always have bottleneck when the dataset grow larger and eventually
            causing the search and indexing gets slower as it is.
          </p>
        </samp>
        <samp>
          SELECT J.id AS `Jobs__id`, J.name AS `Jobs__name`, J.media_id AS `Jobs__media_id`, J.job_category_id AS `Jobs__job_category_id`, J.job_type_id AS `Jobs__job_type_id`, J.description AS
          `Jobs__description`, J.detail AS `Jobs__detail`, J.business_skill AS `Jobs__business_skill`, J.knowledge AS `Jobs__knowledge`, J.location AS `Jobs__location`, J.activity AS `Jobs__activity`,
          J.academic_degree_doctor AS `Jobs__academic_degree_doctor`, J.academic_degree_master AS `Jobs__academic_degree_master`, J.academic_degree_professional AS
          `Jobs__academic_degree_professional`, J.academic_degree_bachelor AS `Jobs__academic_degree_bachelor`, J.salary_statistic_group AS `Jobs__salary_statistic_group`, J.salary_range_first_year AS
          `Jobs__salary_range_first_year`, J.salary_range_average AS `Jobs__salary_range_average`, J.salary_range_remarks AS `Jobs__salary_range_remarks`, J.restriction AS `Jobs__restriction`,
          J.estimated_total_workers AS `Jobs__estimated_total_workers`, J.remarks AS `Jobs__remarks`, J.url AS `Jobs__url`, J.seo_description AS `Jobs__seo_description`, J.seo_keywords AS
          `Jobs__seo_keywords`, J.sort_order AS `Jobs__sort_order`, J.publish_status AS `Jobs__publish_status`, J.version AS `Jobs__version`, J.created_by AS `Jobs__created_by`, J.created AS
          `Jobs__created`, J.modified AS `Jobs__modified`, J.deleted AS `Jobs__deleted`, JC.id AS `JobCategories__id`, JC.name AS `JobCategories__name`, JC.sort_order AS `JobCategories__sort_order`,
          JC.created_by AS `JobCategories__created_by`, JC.created AS `JobCategories__created`, JC.modified AS `JobCategories__modified`, JC.deleted AS `JobCategories__deleted`, JT.id AS
          `JobTypes__id`, JT.name AS `JobTypes__name`, JT.job_category_id AS `JobTypes__job_category_id`, JT.sort_order AS `JobTypes__sort_order`, JT.created_by AS `JobTypes__created_by`, JT.created
          AS `JobTypes__created`, JT.modified AS `JobTypes__modified`, JT.deleted AS `JobTypes__deleted` FROM jobs J LEFT JOIN job_categories JC ON J.job_category_id = JC.id LEFT JOIN job_types JT ON
          J.job_type_id = JT.id -- Additional LEFT JOINs for other tables go here WHERE ( MATCH(J.name, J.description, J.detail, J.business_skill, J.knowledge, J.location, J.activity,
          J.salary_range_remarks, J.restriction, J.remarks, JC.name, JT.name) AGAINST ('キャビンアテンダント*' IN BOOLEAN MODE) ) AND J.publish_status = 1 AND J.deleted IS NULL GROUP BY J.id ORDER BY
          J.sort_order DESC, J.id DESC LIMIT 50 OFFSET 0;
        </samp>
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbBtn } from "mdbvue";
import { APP_PATH, APP_ENV } from "../Helpers/config";

export default {
  name: "Q2",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBtn,
  },
  methods: {
    downloadSQL() {
      window.open(`${APP_PATH[APP_ENV]}assets/task_db.sql`);
    },
  },
};
</script>
